import React from "react";
import style from "./styles/index.module.scss";
import { closeIcon, openIcon } from "../../images";
import classNames from "classnames";
import { ChangeColors, ChangeOpacity, LoadJson } from "../../components/LayoutComponent";

const SettingsView = ({ open, onChangeOpen }) => {
  return (
    <div
      className={classNames(style.settings, {
        [style["close"]]: !open,
      })}
    >
      <LoadJson />
      <ChangeOpacity />
      <ChangeColors />
      <button onClick={onChangeOpen} className={style.settings__button}>
        <img
          className={style.settings__img}
          src={open ? closeIcon : openIcon}
          alt="button-arrow"
        />
      </button>
      <a download href="/output_data_test.json">Скачать пример json</a>
    </div>
  );
};

export default SettingsView;
