import React from "react";
import style from "./styles/index.module.scss";

const Checkbox = ({ label, name, ...props }) => {
  return (
    <div className={style.checkbox}>
      <label className={style.checkbox__label} htmlFor={name}>
        {label}
      </label>
      <input
        className={style.checkbox__input}
        type="checkbox"
        name={name}
        id={name}
        {...props}
      />
    </div>
  );
};

export default Checkbox;
