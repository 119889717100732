import style from "./styles/index.module.scss";
import React from "react";
import { Settings, Steps } from "../../components/LayoutComponent";

const LayoutView = ({ children }) => {
  return (
    <div className={style.layout}>
      <Settings />
      <Steps />
      <div className={style.layout__children}>{children}</div>
    </div>
  );
};

export default LayoutView;
