import React from "react";
import { AlgorithmComponent } from "../components";
import { LayoutView } from "../layout"

const Algorithm = () => {
  return (
    <LayoutView>
      <AlgorithmComponent />
    </LayoutView>
  );
};

export default Algorithm;
