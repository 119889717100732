import React from "react";
import style from "./styles/index.module.scss";

const ModalContent = ({ content, onClick }) => {
  return (
    <div onClick={onClick} className={style.modal}>
      <div className={style.modal__window}>
        <h1 className={style.modal__title}>Ошибка</h1>
        <p className={style.modal__description}>{content}</p>
		<button onClick={onClick} className={style.modal__button}>Исправить</button>
      </div>
    </div>
  );
};

export default ModalContent;
