import React from "react";
import style from "./styles/index.module.scss";

const InputColor = ({ name, label, ...props }) => {
  return (
    <div className={style.input_color}>
      <label className={style.input_color__label} htmlFor={name}>{label}</label>
      <input
        id={name}
		name={name}
        className={style.input_color__input}
        type="color"
        {...props}
      />
    </div>
  );
};

export default InputColor;
