import React, { useState } from "react";
import { SettingsView } from "../../views";

const Settings = () => {
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  return <SettingsView open={open} onChangeOpen={handleOpen} />
};

export default Settings;
