import React from "react";
import Cargo from "./Cargo";

const Cargos = ({ cargos, last, ...props }) => {
  return cargos.length !== 0
    ? cargos.slice(0, last !== null ? last + 1 : undefined).map((cargo, index) => (
        <Cargo key={cargo.id} cargo={cargo} last={last === index} {...props} />
      ))
    : null
};

export default Cargos;
