import React from "react";
import {
  PlaneGeometry,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera as ThreePerspectiveCamera,
  OrthographicCamera,
} from "three";
import { uuidv4 } from "../../../utils/uuid/uuid";


export default class CameraAbstract extends React.Component {
  constructor(props) {
    super(props);
    const {
      scene,
      enableVR,
      enableQuadCamera,
      renderer,
      effect,
      canvasWidth,
      canvasHeight,
      addRenderCall,
      aspect,
      degreesToCameraPerspective,
      // canvasDomElement,
      // onComponentInit,
      onComponentReady,
      // getComponentByName,
      // getComponentByUuid,
      // enableShadows,
      // debug,
      uuid,
    } = props.requiredPropertys;
    this.state = { ready: false };
    this.aspect = enableVR ? canvasWidth / canvasHeight : aspect;
    this.degreesToCameraPerspective = enableVR
      ? 60
      : degreesToCameraPerspective;
    if (props.degreesToCameraPerspective && !enableVR) {
      this.degreesToCameraPerspective = props.degreesToCameraPerspective;
    }
    if (props.aspect && !enableVR) {
      this.aspect = props.aspect;
    }
    this.far = props.far ? props.far : 1000;
    this.near = props.near ? props.near : 0.1;
    if (props.orthoganalEnable) {
      this.obj = new OrthographicCamera(
        canvasWidth / -2,
        canvasWidth / 2,
        canvasHeight / 2,
        canvasHeight / -2,
        0.1,
        500
      );
      if (props.zoomOrhoganal) {
        this.obj.zoom = props.zoomOrhoganal;
      }
    } else {
      this.obj = new ThreePerspectiveCamera(
        this.degreesToCameraPerspective,
        this.aspect,
        this.near,
        this.far
      );
    }
    this.obj.position.set(...props.position);
    this.obj.rotation.set(props.rotation);
    this.obj.lookAt(scene.position);

    if (enableVR) {
      this.rayMesh = this.createMeshToRay();
      this.rayMesh.position.set(0, 0, 5);
      this.aimMesh = this.createMeshToRay();
      // this.aimMesh.rotation.set(0, 0, -5);
      this.obj.add(this.aimMesh);
      this.obj.add(this.rayMesh);
    }
    this.obj.name = this.props.name ? this.props.name : `No name ${uuidv4()}`;
    this.obj.uuid = uuid ? uuid : uuidv4();
    if (enableVR || enableQuadCamera) {
      addRenderCall("VR", () => this.rendererEffect(effect, scene), uuidv4());
    }
    if (!enableVR && !enableQuadCamera) {
      addRenderCall(
        "rerenderer",
        () => renderer.render(scene, this.obj),
        uuidv4()
      );
    }
    window.addEventListener("resize", this.resize);
    onComponentReady({
      name: this.obj.name,
      uuid: this.obj.uuid,
    });
  }

  /**
   * режим отрисовки VR или QUAD_CAM_MOD
   * @param effect
   * @param scene
   */
  rendererEffect = (effect, scene) => {
    effect.render(scene, this.obj);
  };

  /**
   *  - прицел в режиме VR
   *   todo: сделать более красивую иконку и добавить анимаций
   *   !заглушка под прицел
   */
  createMeshToRay = () => {
    let geometry = new PlaneGeometry(0.1, 0.1);
    let material = new MeshBasicMaterial({ color: 0xffff00, side: DoubleSide });
    return new Mesh(geometry, material);
  };
}
