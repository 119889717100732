import { AbstractObject, CONST } from "@garpix/gengien";
import { uuidv4 } from "../../../utils/uuid/uuid";
import { GridHelper } from "three";

class CustomGrid extends AbstractObject {
  init = () => {
    const {
      requiredPropertys,
      name = CONST.DATA_OBJECT_SCENE.GRID.name,
      uuid = uuidv4(),
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      size = 100,
      divisions = 100,
      colorCenterLine = "#ffffff",
      color = "#ffffff",
    } = this.props;
    const { scene } = requiredPropertys;
    this.obj = new GridHelper(size, divisions, colorCenterLine, color);
    this.setPosition(position);
    this.setRotation(rotation);
    this.obj.name = name;
    this.uuid = uuid;
    this.addToScene(scene);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (this.props.color !== prevProps.color || this.props.colorCenterLine !== prevProps.colorCenterLine) {
      this.removeObject();
      this.init();
    }
  }

  render() {
    return null
  }
}

export default CustomGrid;
