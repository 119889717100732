import { AbstractObject } from '@garpix/gengien'
import { OrbitControls as ThreeOrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { uuidv4 } from "../../../utils/uuid/uuid";
import { Vector3 } from 'three'


class CustomOrbitControl extends AbstractObject {
  constructor(props) {
    super(props);
    this.uuid = uuidv4();
  }

  init = props => {
    const {
      requiredPropertys,
      minDistance = 5,
      maxDistance = 2000,
      enablePan = true,
      target = new Vector3(0, 0, 0),
      camera,
      name = "ORBIT_CONTROLS",
      uuid = this.uuid,
      enabled = true
    } = props;
    const { canvasDomElement } = requiredPropertys;
    if (!this.obj instanceof ThreeOrbitControls || !this.obj) {
      this.initComponent(name, uuid);
      this.obj = new ThreeOrbitControls(camera, canvasDomElement);
    }
    this.obj.enabled = enabled;
    this.obj.rotateSpeed = 0.3;
    this.obj.zoomSpeed = 0.9;
    this.obj.enablePan = enablePan;
    this.obj.target = target;

    this.obj.minDistance = minDistance;
    this.obj.maxDistance = maxDistance;
    this.name = name;
    this.uuid = uuid;

    if (!this.obj instanceof ThreeOrbitControls) {
      this.readyComponent();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.init(this.props);
    this.obj.update()
  }

  componentDidMount() {
    this.init(this.props);
    this.obj.update()
  }

  render() {
    return null;
  }
}

export default CustomOrbitControl;
