import { Route, Switch, withRouter } from "react-router-dom";

import { Page404 } from "./pages/Page404";
import { HomePage, NewPage } from "./pages/Home";
import { Algorithm } from "./pages"

import { PATHS } from "./const";
import React from "react";


function App() {

  return (
      <Switch>
        <Route path={PATHS.HOME.path} exact={PATHS.HOME.exact} component={Algorithm} />
        <Route path={PATHS.NEW.path} exact={PATHS.HOME.exact} component={NewPage} />
        
        <Route component={Page404} />
      </Switch>
  );
}

export default withRouter(App);