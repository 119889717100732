import { useEffect } from 'react'
import api from '../api';
import style from './style/home.module.scss';
import { useHistory } from "react-router-dom";
import { Pallet } from '../services';
import { Scene, SceneCopy } from '../components';
import { PATHS } from '../const'

const socket = api.socketApi

const HomePage = () => {

  const history = useHistory();

  useEffect(() => {
    if (socket.socket) {
      socket.on('open', () => {
        socket.emit('connect', { status: 'access' })
      })
      socket.startPickingWebSocket(({ data }) => {
        let path;
        socket.emit("start_picking", { status: "access" });
        if (data.pallets.length > 1) {
          path = PATHS.TWO_PALLET_PICKING.path;
        } else {
          path = PATHS.SINGLE_PALLET_PICKING.path;
        }
        Pallet.save(data.pallets);
        history.push(path);
      });
    }
  }, [])

  return (
    <div className={style['home-page']}>
      
      <Scene></Scene>
    </div>
  )
}
const NewPage = () => {

  const history = useHistory();

  useEffect(() => {
    if (socket.socket) {
      socket.on('open', () => {
        socket.emit('connect', { status: 'access' })
      })
      socket.startPickingWebSocket(({ data }) => {
        let path;
        socket.emit("start_picking", { status: "access" });
        if (data.pallets.length > 1) {
          path = PATHS.TWO_PALLET_PICKING.path;
        } else {
          path = PATHS.SINGLE_PALLET_PICKING.path;
        }
        Pallet.save(data.pallets);
        history.push(path);
      });
    }
  }, [])

  return (
    <div className={style['home-page']}>
      
      <SceneCopy></SceneCopy>
    </div>
  )
}

export {
  HomePage,
  NewPage
}