import React from "react";
import style from "./styles/index.module.scss";

const CargoInfo = ({ info }) => {
  const { id, size, position, sort, stacking, mass, group_id, turnover, rank} = info;
  const { height, length, width } = size;
  const { x, y, z } = position;
  return (
    <div className={style.info}>
      <p className={style.info__id}>
        <span className={style.info__topic}>id:</span>
        {id}
      </p>
      <p className={style.info__sort}>
        <span className={style.info__topic}>group_id:</span>
        {group_id}
      </p>
      <p className={style.info__sort}>
        <span className={style.info__topic}>sort:</span>
        {sort}
      </p>
      <p className={style.info__size}>
        <span className={style.info__topic}>size:</span>
        {`{ height: ${height}, length: ${length}, width: ${width} }`}
      </p>
      <p className={style.info__position}>
        <span className={style.info__topic}>position:</span>
        {`{ x: ${x}, y: ${y}, z: ${z} }`}
      </p>
      <p className={style.info__sort}>
        <span className={style.info__topic}>stacking:</span>
        {stacking ? "true": "false"}
        <span className={style.info__topic}> turnover:</span>
        {turnover ? "true": "false"}
      </p>
      <p className={style.info__sort}>
        <span className={style.info__topic}>mass:</span>
        {mass}
      </p>
        <p className={style.info__sort}>
            <span className={style.info__topic}>rank:</span>
            {rank }
        </p>
    </div>
  );
};

export default CargoInfo;
