import SocketApi from "./SocketApi";
import BaseApi from "./base_api";
import { Pallet } from "../services";

class Api extends BaseApi {
  constructor(url, url_ws) {
    super(url);
    this.socketApi = new SocketApi(url_ws);
  }

  login = async ({ username, password }) => {
    const res = await this.post("/user/token-auth/", { username, password });
    const data = res.data;
    this.setAuthToken(data.token);
    return data;
  };

  getCurrentUser = async () => {
    const res = await this.get("/user/user/current/");
    return res.data;
  };

  getOrderPalletSingle = async () => {
    const pallet = Pallet.get();
    const res = await this.get(
      `/order/order_pallet/get_calculation/${pallet?.pk}/`
    );
    return res.data;
  };
  
  getOrderCurrentPallet = async (pk) => {
    const res = await this.get(
      `/order/order_pallet/get_calculation/${pk}/`
    );
    return res.data;
  };

  getOrderPallets = async () => {
    const { pallet_first, pallet_second } = Pallet.get();
    
    const res_first = this.get(
      `/order/order_pallet/get_calculation/${pallet_first?.pk}/`
    );
    const res_second = this.get(
      `/order/order_pallet/get_calculation/${pallet_second?.pk}/`
    );
    const result = await Promise.all([res_first, res_second])
    const data_pallets = {
      leftPallet: result[0].data.pallet_info,
      rightPallet: result[1].data.pallet_info,
    }
    const group_pallets = {
      leftGroup: result[0].data.active_cell,
      rightGroup: result[1].data.active_cell,
    }
    return { data_pallets, group_pallets }
  };

  getCurrentDevice = async () => {
    const mac_address = process.env.REACT_APP_MAC_ADDRESS
    const res = await this.get(`/user/device/${mac_address}/`)
    return res.data;
  }

  getDispatchingData = async () => {
    const data = {
      calcRecalc: {
        calcTime: "05:12",
        recalcTime: "09:10",
      },
      collation: "05:12",
      palletId: "18NA127G",
      dispatching: {
        id: "1415151",
        quantity: 5,
      },
    };
    return await data;
  };

  getOrderListData = async ({page,filter, search_type, search}) => {
    const {data} = await this.get('/order/dispatcher_statistic',
        { 
          page: page, ordering: filter, 
          device_id : search_type === 'device_id' ? search : '',
          order_id : search_type === 'order_id' ? search : '',
          pallet_id : search_type === 'pallet_id' ? search : '',
        }
     );
    console.log("loadData", search_type, data);
    return data;
  }
  getActiveDevicesData = async (params) => {
    // sendData
    console.log('loadData', params);
    const data = {
      count: 12,
      results: [
        { id: 1, mac: '00:AB:CD:EF:11:22', deviceId: 143, status: true },
        { id: 2, mac: '00:AB:CD:EF:11:22', deviceId: 143, status: false},
      ]
    }
    return await data
  }
  
  getTopPickers = async () => {
    const res = await this.get('/user/statistics/5/')
    return res.data;
  }
}
const MAIN_URL = process.env.REACT_APP_API_URL;
const MAIN_URL_WS = process.env.REACT_APP_API_URL_WS || "";

// const api = new Api(`http://localhost:8010/proxy/api`);

const api = new Api(`${MAIN_URL}`, `${MAIN_URL_WS}`);

export const pageCount = 15;
export default api;
