import { Canvas, PerspectiveCamera, Camera, Grid, DevMode, OrbitControl, AmbientLight, DirectionalLight, Raycaster, DragAndDropControl } from '@garpix/gengien'
import React from 'react'
import { Vector3, TextureLoader } from 'three'
import { MeshStandardMaterial } from "three";
import { Box, GenericGroupObject } from "@garpix/gengien";
import СontrolPanel from "./СontrolPanel"

const material = new MeshStandardMaterial({
  wireframe: true,
  color: '#8b4513',
})

const materialCargo = new MeshStandardMaterial({
  wireframe: false,
  color: '#8b4513',
  map: new TextureLoader().load('rectangle1.jpg'),
  normalMap: new TextureLoader().load('rectangle1.jpg'),
})

const materialCargoActive = new MeshStandardMaterial({
  wireframe: true,
  color: '#1d7ff7',
  map: new TextureLoader().load('rectangle1.jpg'),
  normalMap: new TextureLoader().load('rectangle1.jpg'),
})


class SceneCopy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      container: {},
      cargos: [],
      linkToSceneObject: null,

      controlsEnable: true,
      current_element: null,
      active_cargo_indx: 0,
    };
  }

  componentDidMount() {

    let cargos = []

    let json = require('./output_copy.json');
    const dataPallet = json;

    const cont = dataPallet.cargo_space
    console.log(cont, "cont")
    const container = {
      size: [cont.length / 1000, cont.max_height / 1000, cont.width / 1000],
      position:  [0, cont.max_height / 2000, 0]
    }
    console.log(container, "container")

    dataPallet.cargos.forEach(element => {
      const cargo = {
        size: [element.size[0] / 1000, element.size[1] / 1000, element.size[2] / 1000],
        position: [element.position[0] / 1000, (element.position[1] - cont.height) / 1000, element.position[2] / 1000],
        id: element.id,
        sort: element.sort
      }
      cargos.push(cargo);
    });

    this.setState({
      ...this.state,
      container: container,
      cargos: cargos,
    });
  }

  getLinkScene = (scene) => {
    this.setState({
      linkToSceneObject: scene
    });
  };

  setCurrentBox = (mesh = null) => {
    this.setState({
      ...this.state,
      current_element: mesh
    })
  }
  fixedCameras = (controlsEnable) => {
    console.log(`controlsEnable`, controlsEnable)
    this.setState({
      ...this.state,
      controlsEnable: controlsEnable
    });
  };

  backStep = () => {
    if (this.state.active_cargo_indx > 0) {
      this.setState({
        ...this.state,
        active_cargo_indx: this.state.active_cargo_indx - 1
      });
    }

  }
  nextStep = () => {
    if (this.state.active_cargo_indx + 1 < this.state.cargos.length) {
      this.setState({
        ...this.state,
        active_cargo_indx: this.state.active_cargo_indx + 1
      });
    }
  }
  end = () => {
    this.setState({
      ...this.state,
      active_cargo_indx: this.state.cargos.length - 1
    });
  }
  start = () => {
    this.setState({
      ...this.state,
      active_cargo_indx: 0
    });
  }

  render() {
    return (
      <>
        <СontrolPanel
          cargos={this.state.cargos}
          active_cargo_indx={this.state.active_cargo_indx}
          start={this.start}
          end={this.end}
          backStep={this.backStep}
          nextStep={this.nextStep}
        />
        <Canvas
          enableShadows={true}
          enableVR={false}
          debug={false}
          fullscreen={this.props.fullscreen ?? true}
          getScene={!this.state.linkToSceneObject ? this.getLinkScene : null}
        >
          <Camera
            position={[3, 4, 3]}
            near={1}
            far={700}
            degrisToCameraPerspectiv={20}
            fullscreen={true}
          >
            <OrbitControl
              minDistance={1}
              maxDistance={1000000}
              enablePan={true}
              enabled={this.state.controlsEnable}
            />
            <Raycaster />
            {/* <DragAndDropControl
            fixedCameras={this.fixedCameras}
            uuidSelectObj={this.state.current_element}
            uuidObject={this.state.current_element}
          /> */}
          </Camera>

          <GenericGroupObject >
          <Box
            position={this.state.container.position}
            color={'#1d7ff7'}
            scale={this.state.container.size}
            material={material}
          />
        </GenericGroupObject>

          {this.state.cargos.map((cargo, i) => {
            if (i <= this.state.active_cargo_indx) {
              return (
                <GenericGroupObject key={i} scale={cargo.size}>
                  <Box
                    key={i}
                    position={cargo.position}
                    color={'#4d5a50'}
                    scale={cargo.size}
                    material={materialCargo}
                    callbacks={[
                      {
                        type: "click",
                        event: (e) => {
                          console.log(`size`, cargo.size)
                          this.setCurrentBox(e.data.target.uuid)
                        }
                      }
                    ]}
                  />
                </GenericGroupObject>
              )
            }

            return
          })}
          {/* {this.state.cargos.map((cargo, i) => (
          <GenericGroupObject key={i} scale={cargo.size}>
            <Box
              key={i}
              position={cargo.position}
              color={'#1d7ff7'}
              scale={cargo.size}
              material={materialCargo}
              callbacks={[
                {
                  type: "click",
                  event: (e) => {
                    console.log(`size`, cargo.size)
                    this.setCurrentBox(e.data.target.uuid)
                  }
                }
              ]}
            />
          </GenericGroupObject>
        ))} */}

          <Grid
            color='#4d5a50'
            colorCenterLine='#045416'
            divisions={30}
            size={10}
            position={[0, 0, 0]}
          />
          <AmbientLight intensity={0.5} />
          <DirectionalLight intensity={1} position={[3.5, 8, 0]} />
        </Canvas >



      </>
    );
  }
}

export default SceneCopy;
