import React, { Component } from "react";
import {
  Canvas,
  AmbientLight,
  DirectionalLight
} from "@garpix/gengien";
import { CustomCamera, CustomOrbitControl } from "./CustomComponent";
import { Cargos, Grid, RestrictBox, UnpackedCargos } from "./SceneElements";
import { Vector3 } from "three";

class SceneNew extends Component {
  constructor() {
    super();
    this.state = {
      degreesToCameraPerspective: 60,
      minDistance: 2,
      maxDistance: 10,
      targetCamera: new Vector3(0, 0, 0),
      positionCamera: [0, 0, 0]
    };
  }

  init = () => {
    const { cargoSpace } = this.props.data;
    const { loading_size } = cargoSpace;
    const { length, height, width } = loading_size;
    this.setState({
      targetCamera: new Vector3(0, height / 2, 0),
      positionCamera: [length * 3, height / 2, width * 3],
    });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.init();
    }
  }

  render() {
    const { cargos, cargoSpace } = this.props.data;

    return (
      <Canvas
        enableShadows={true}
        enableVR={false}
        debug={false}
        fullscreen={false}
      >
        <CustomCamera
          position={this.state.positionCamera}
          degreesToCameraPerspective={this.state.degreesToCameraPerspective}
        >
          <CustomOrbitControl
            minDistance={this.state.minDistance}
            maxDistance={this.state.maxDistance}
            target={this.state.targetCamera}
          />
        </CustomCamera>
        <Cargos
          cargos={cargos}
          config={this.props.config}
          last={this.props.index}
        />
        <RestrictBox box={cargoSpace}/>
        <Grid config={this.props.config} />
        <UnpackedCargos cargos={this.props.data.unpacked ? this.props.data.unpacked : []} config={this.props.config}/>
        <AmbientLight intensity={0.42} />
        <DirectionalLight intensity={1} position={[-20, 5, -20]} />
      </Canvas>
    );
  }
}

export default SceneNew;
