import React from "react";
import { CustomGrid } from "../CustomComponent";

const Grid = ({ divisions, size, config, ...props }) => {
  const { colorCenterLine, colorLine } = config;

  return (
    <CustomGrid
      divisions={divisions}
      size={size}
      colorCenterLine={colorCenterLine}
      color={colorLine}
      {...props}
    />
  );
};

export default Grid;
