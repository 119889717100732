import { createStoreon } from "storeon";
import { storeonDevtools } from "storeon/devtools";
import { COLORS } from "../const";
import data from "../data/output_data.json";
import { persistState } from '@storeon/localstorage';

const json = (store) => {
  store.on("@init", () => ({ json: data }));
  store.on("json/save", ({}, json) => {
    return { json };
  });
};

const index = (store) => {
  store.on("@init", () => ({ index: null }));
  store.on("index/change", ({}, index) => {
    return { index };
  });
};

const config = (store) => {
  store.on("@init", () => ({
    config: {
      color: COLORS.COLOR,
      activeColor: COLORS.ACTIVE_COLOR,
      colorLine: COLORS.GRID,
      colorCenterLine: COLORS.GRID_CENTER_LINE,
      opacity: 1
    },
  }));
  store.on("config/change", ({ config }, data) => {
    return { config: { ...config, ...data } }
  });
};

const storeonParams = [json, index, config, persistState(['config'])];

if (process.env.NODE_ENV === "development") {
  storeonParams.push(storeonDevtools);
}

export const store = createStoreon(storeonParams);
