import React from "react";
import { useStoreon } from "storeon/react";
import { InputRange } from "../../views";

const ChangeOpacity = () => {
  const { dispatch, config } = useStoreon("config");

  const handleChangeOpacity = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch("config/change", { [name]: value });
  };

  return (
    <InputRange
      onChange={handleChangeOpacity}
	  value={config.opacity}
      name="opacity"
      label="Прозрачность:"
    />
  );
};

export default ChangeOpacity
