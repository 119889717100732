import React, { useState } from "react";
import { LoadJsonView, ModalContent } from "../../views";
import { useStoreon } from "storeon/react";
import { validateJson } from "../../utils";
import ModalComponent from "../ModalComponent";

const LoadJson = () => {
  const [name, setName] = useState("Файл не выбран");
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const { dispatch } = useStoreon();

  const handleChange = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = JSON.parse(event.target.result);
      const validate = validateJson(data);
      if (!validate.status) { 
        setModalContent(validate.message);
        setModalShow(true);
        return
      }
      dispatch("json/save", data);
      dispatch("index/change", null);
      setName(e.target.files[0].name);
    };
    reader.readAsText(e.target.files[0]);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <>
      {modalShow ? (
        <ModalComponent open={modalContent}>
          <ModalContent content={modalContent} onClick={handleCloseModal} />
        </ModalComponent>
      ) : null}
      <LoadJsonView nameFile={name} onChange={handleChange} />
    </>
  );
};

export default LoadJson;
