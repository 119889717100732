import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { StepsView } from "../../views";
import { createCargoInfo } from "../../utils";

const Steps = () => {
  const { json, index, dispatch } = useStoreon("json", "index");
  const [open, setOpen] = useState(true);
  const [steps, setSteps] = useState(false)

  useEffect(() => {
    setSteps(false)
  }, [json])

  const total = json.cargos.length;

  const currentCargo = createCargoInfo(json.cargos, index);

  const handleOpen = () => {
    setOpen((prevState) => !prevState)
  };

  const handleSteps = () => {
    setSteps(prevState => {
      dispatch("index/change", !prevState ? 0 : null);
      return !prevState
    })
  }

  const handleNext = () => {
    const nextIndex = index >= total - 1 ? index : index + 1
    dispatch("index/change", nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = index <= 0 ? 0 : index  - 1
    dispatch("index/change", prevIndex);
  };

  const handleRefresh = () => {
    dispatch("index/change", 0);
  };

  const handleAll = () => {
    dispatch("index/change", total - 1);
  };

  return (
    <StepsView
      open={open}
      steps={steps}
      onChangeSteps={handleSteps}
      onChangeOpen={handleOpen}
      currentCargo={currentCargo}
      current={index}
      total={total}
      onRefresh={handleRefresh}
      onAll={handleAll}
      onPrev={handlePrev}
      onNext={handleNext}
    />
  );
};

export default Steps;
