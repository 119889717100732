import React from "react";
import { Box } from "@garpix/gengien";
import { MeshBasicMaterial, TextureLoader } from "three";

const texture = new TextureLoader().load(
  process.env.PUBLIC_URL + "/textures/cargo-texture-v-2.jpg"
);

const Cargo = ({ cargo, last, config, ...props }) => {
  
  const { size, position } = cargo;
  const { height, length, width } = size;
  const { x, y, z } = position;
  const { color, activeColor, opacity } = config

  const material = new MeshBasicMaterial({ map: texture, transparent: true, opacity: opacity })

  return (
    <Box
      scale={[length, height, width]}
      position={[x, y, z]}
      color={last ? activeColor : color}
      material={material}
      {...props}
    />
  );
};

export default Cargo;
