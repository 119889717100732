import React from "react";
import { Box } from "@garpix/gengien";
import { MeshBasicMaterial } from "three";
import { COLORS } from "../../../const"

const RestrictBox = ({ box, ...props }) => {
  const { position, loading_size } = box;
  const {length, width, height} = loading_size;
  const material = new MeshBasicMaterial({ wireframe: true });

  return (
    <Box
      scale={[length, height, width]}
      position={position}
      color={COLORS.RESTRICT_BOX}
      material={material}
      {...props}
    />
  );
};

export default RestrictBox;
