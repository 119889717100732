export const PATHS = {
  HOME: {
    path: "/",
    exact: true,
  },
  NEW: {
    path: "/x5",
  },
  SINGLE_PALLET_PICKING: {
    path: "/picker/single_pallet_picking",
  }
};

export const COLORS = {
  GRID: "#909090",
  GRID_CENTER_LINE: "#008000",
  COLOR: "#505050",
  ACTIVE_COLOR: "#00ff00",
  RESTRICT_BOX: "#A0A0A0",
  PALLET: "#cd853f",
};

export const BOXES_TYPE = {
  TYPE_ACTIVE_CELL: "ACTIVE",
  TYPE_PRE_ACTIVE_CELL: "PRE_ACTIVE",
  TYPE_PICKED_CELL: "PICKED",
  TYPE_NOT_PICKED_CELL: "NOT_PICKED",
};

// Cargo types
export const TYPE = {
  LONGER: "longer",
  TALL: "tall",
};

// GGE const
export const DATA_OBJECT_SCENE = {
  ORBIT_CONTROLS: {
    name: "ORBIT_CONTROLS",
  },
  MAIN_CAMERA: {
    name: "CAMERA",
  }
}

export const VIEW = {
  FILL_LIFT: 'left',
  FILL_RIHT: 'right',
  FILL_TOP: 'top',
  NOT_FILLED: 'not_filled'
}

export const AUTH_TOKEN_KEY = 'auth_token';

export const PALLET_INFO = "pallet_info";

export const DEVICE_INFO = "device_info";

export const VALIDATION_PARAMS = {
  CARGO_KEYS: ["id", "position", "size", "sort"],
  RESTRICT_BOX_KEYS: ["position", "length", "width", "maxHeight"],
  CARGO_POSITION_KEYS: ["x", "y", "z"],
  CARGO_SIZE_KEYS: ["height", "length", "width"],
  JSON_KEYS: ["cargoSpace", "cargos"]
}
