import React from "react";
import Cargo from "./Cargo";

const UnpackedCargos = ({ cargos, ...props }) => {
  return cargos.length !== 0
    ? cargos.map((cargo, index) => (
        <Cargo key={cargo.id} cargo={cargo} current={index} {...props} />
      ))
    : null
};

export default UnpackedCargos;
