import { VALIDATION_PARAMS } from "../../const";

const validateSize = (size, index, unpacked) => {
  const arr = Object.keys(size);
  for (let key in VALIDATION_PARAMS.CARGO_SIZE_KEYS) {
    const prop = VALIDATION_PARAMS.CARGO_SIZE_KEYS[key];
    if (!arr.includes(prop)) {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].position не содержит свойства ${prop}...`,
      };
    }
    if (typeof size[prop] !== "number") {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].position.${prop} должен иметь тип number...`,
      };
    }
  }
};

const validatePosition = (position, index, unpacked) => {
  const arr = Object.keys(position);
  for (let key in VALIDATION_PARAMS.CARGO_POSITION_KEYS) {
    const prop = VALIDATION_PARAMS.CARGO_POSITION_KEYS[key];
    if (!arr.includes(prop)) {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].position не содержит свойства ${prop}...`,
      };
    }
    if (typeof position[prop] !== "number") {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].position.${prop} должен иметь тип number...`,
      };
    }
  }
};

const validateCargo = (cargo, index, unpacked) => {
  if (Array.isArray(cargo) || typeof cargo !== "object") {
    return {
      status: false,
      message: `${
        unpacked ? "unpacked" : "cargos"
      }[${index}] не является объектом...`,
    };
  }
  const arr = Object.keys(cargo);
  for (let key in VALIDATION_PARAMS.CARGO_KEYS) {
    const prop = VALIDATION_PARAMS.CARGO_KEYS[key];
    if (!arr.includes(prop)) {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}] не содержит свойства ${prop}...`,
      };
    }
    if (prop === "id" && typeof cargo[prop] !== "number") {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].id должен иметь тип number...`,
      };
    }
    if (prop === "sort" && typeof cargo[prop] !== "number") {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].sort должен иметь тип number...`,
      };
    }
    if (
      prop === "position" &&
      (Array.isArray(cargo[prop]) || typeof cargo[prop] !== "object")
    ) {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].position не является объектом...`,
      };
    } else if (prop === "position") {
      const result = validatePosition(cargo[prop], index, unpacked);
      if (result && !result?.status) {
        return result;
      }
    }
    if (
      prop === "size" &&
      (Array.isArray(cargo[prop]) || typeof cargo[prop] !== "object")
    ) {
      return {
        status: false,
        message: `${
          unpacked ? "unpacked" : "cargos"
        }[${index}].size не является объектом...`,
      };
    } else if (prop === "size") {
      const result = validateSize(cargo[prop], index, unpacked);
      if (result && !result?.status) {
        return result;
      }
    }
  }
};

const validateCargos = (cargos, unpacked) => {
  if (!Array.isArray(cargos)) {
    return {
      status: false,
      message: `${unpacked ? "unpacked" : "cargos"} не является массивом...`,
    };
  }
  for (let i in cargos) {
    const result = validateCargo(cargos[i], i, unpacked);
    if (result && !result?.status) {
      return result;
    }
  }
};

const validateBoxPosition = (position) => {
  for (let i in position) {
    const coordinate = position[i];
    if (typeof coordinate !== "number") {
      return {
        status: false,
        message: `cargoSpace.position[${i}] не является number...`,
      };
    }
  }
};

const validateRestrictBox = (box) => {
  if (Array.isArray(box) || typeof box !== "object") {
    return { status: false, message: `cargoSpace не является объектом...` };
  }
  const arr = Object.keys(box);
  for (let key in VALIDATION_PARAMS.RESTRICT_BOX_KEYS) {
    const prop = VALIDATION_PARAMS.RESTRICT_BOX_KEYS[key];
    // if (!arr.includes(prop)) {
    //   return {
    //     status: false,
    //     message: `cargoSpace не содержит свойства ${prop}...`,
    //   };
    // }
    if (prop === "position" && !Array.isArray(box[prop])) {
      return {
        status: false,
        message: `cargoSpace.position не является массивом...`,
      };
    } else if (prop === "position") {
      const result = validateBoxPosition(box[prop]);
      if (result && !result?.status) {
        return result;
      }
    }
  }
};

export const validateJson = (json) => {
  if (Array.isArray(json) || typeof json !== "object") {
    return { status: false, message: `json-file не является объектом...` };
  }
  const arr = Object.keys(json);
  for (let key in VALIDATION_PARAMS.JSON_KEYS) {
    const prop = VALIDATION_PARAMS.JSON_KEYS[key];
    if (!arr.includes(prop)) {
      return {
        status: false,
        message: `json-file не содержит свойства ${prop}...`,
      };
    }
    if (prop === "cargoSpace") {
      const resultBox = validateRestrictBox(json.cargoSpace);
      if (resultBox && !resultBox?.status) {
        return resultBox;
      }
    }
    if (prop === "cargos") {
      const result = validateCargos(json.cargos);
      if (result && !result?.status) {
        return result;
      }
    }
    // if (prop === "unpacked") {
    //   const result = validateCargos(json.unpacked, true);
    //   if (result && !result?.status) {
    //     return result;
    //   }
    // }
  }
  return { status: true, message: null };
};
