import React from "react";
import style from "./styles/index.module.scss";

const InputRange = ({ label, name, ...props }) => {
  return (
    <div className={style["input-range"]}>
      <label className={style["input-range__label"]} htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
		    min="0"
		    step="0.1"
		    max="1"
        className={style["input-range__input"]}
        type="range"
        {...props}
      />
    </div>
  );
};

export default InputRange;
