export const createCargoInfo = (cargos, index) => {
  if (index !== null) {
    const cargo = cargos[index];
    const { x, y, z } = cargo.position;
    const { height, width, length } = cargo.size;
    return {
      id: cargo.id,
      position: {
        x: Number(x).toFixed(2),
        y: Number(y).toFixed(2),
        z: Number(z).toFixed(2),
      },
      size: {
        height: Number(height).toFixed(2),
        length: Number(length).toFixed(2),
        width: Number(width).toFixed(2),
      },
      sort: Number(cargo.sort),
      stacking: cargo.stacking,
      rank: cargo?.rank,
      turnover: cargo.turnover,
      mass: Number(cargo.mass),
      group_id: cargo.cargo_id
    };
  }
  return null;
};
