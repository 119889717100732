import React from "react";
import { useStoreon } from "storeon/react";
import { InputColor } from "../../views";

const inputs = [
  {
    id: 1,
    name: "color",
    label: "Цвет коробки:",
  },
  {
    id: 2,
    name: "activeColor",
    label: "Цвет активной коробки:",
  },
  {
    id: 3,
    name: "colorLine",
    label: "Цвет сетки:",
  },
  {
    id: 4,
    name: "colorCenterLine",
    label: "Цвет центральной оси:",
  },
];

const ChangeColors = () => {
  const { config, dispatch } = useStoreon("config");

  const handleChange = (e) => {
    const name = e.target.name;
	  const value = e.target.value;
	  dispatch("config/change", { [name]: value })
  };

  return inputs.map(({ id, name, label }) => (
    <InputColor
      key={id}
      name={name}
      label={label}
      value={config[name]}
      onChange={handleChange}
    />
  ));
};

export default ChangeColors;
