import BaseApiForSocket from './BaseApiForSocket';

class SocketApi extends BaseApiForSocket {
  constructor(url_ws) {
    super(url_ws);
  }

  loginWebSocket = (calback) => {
    this.on('login', calback)
  }

  nextCellWebSocket = (callback) => {
    this.on('picking_next_cell', callback)
  }

  finishPickingWebSocket = (callback) => {
    this.on('finish_picking', callback)
  }

  startPickingWebSocket = (calback) => {
    this.on('start_picking', calback)
  }

  switchPalletWebSocket = (callback) => {
    this.on('active_pallet', callback)
  }
}

export default SocketApi;
