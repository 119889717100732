import queue from "fastq";

class SocketBase {
  constructor(url_ws) {
    this.url = url_ws;
    this.queue = queue.promise(this.worker, 1);
    this.nextRequest = ["login", "start_picking", "logout", "finish_picking", "stop_picking" ];
  }

  worker = async (callback) => {
    await callback();
  };

  activate = () => {
    this.socket.onopen = (e) => {
      if (this.listeners["open"]) {
        this.listeners["open"]();
      }
    };
    this.socket.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      if (data.type)
        if (this.listeners[data.type] && this.nextRequest.includes(data.type)) {
          await this.queue.push(
            this.listeners[data.type].bind(this, data)
          )
        } else if (this.listeners[data.type]) {
          this.listeners[data.type](data)
        } else {
          console.log("Тип события не определен!");
        }
    };
    this.socket.onclose = (event) => {
      if (this.listeners["disconnect"]) {
        this.listeners["disconnect"](event)
        localStorage.setItem("connectionStatus", false);
      }
      setTimeout(this.activate, 2000);
    };
    this.socket.onerror = (error) => {
      if (this.listeners["error"]) {
        this.listeners["error"](error);
        localStorage.setItem("connectionStatus", false);
      }
    };
  };

  on = (event, callback) => {
    this.listeners[event] = callback;
  };

  removeEventSocket = (eventName) => {
    delete this.listeners[eventName];
  };

  emit = (event, data = {}, key = "type") => {
    this.socket.send(
      JSON.stringify({
        [key]: event,
        ...data,
      })
    );
    return data;
  };

  checkOnCorrectUrls = () => {
    return !!this.url;
  };

  init = (start, api, roomId) => {
    const statusUrl = this.checkOnCorrectUrls();
    if (start && statusUrl) {
      this.api = api ? api : "";
      this.roomId = roomId ? roomId : "";
      this.connectionURI = `${this.url}${this.api}${this.roomId}`;
      this.socket = new WebSocket(this.connectionURI);
      this.listeners = {};
      this.activate();
      return this.socket;
    }
    this.listeners = {};
  };
}

export default SocketBase;
