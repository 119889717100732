import React from "react";
import { AbstractObject } from "@garpix/gengien"
import CameraAbstract from "./CameraAbstract";
import { uuidv4 } from "../../../utils/uuid/uuid";

/**
 * - класс описывающий универсаную камеру на основе пропсов решает какой тип камеры будет использоватся
 * @class Camera
 */
class Camera extends AbstractObject {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      uuidSelectObj: null,
    };
    this.obj = null;
    this.props = props;
  }

  setSelectedObj = (uuid) => {
    this.setState({
      uuidSelectObj: uuid,
    });
  };

  componentDidMount() {
    const {
      requiredPropertys,
      zoomOrhoganal = null,
      zoomPerspective = null,
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      degreesToCameraPerspective,
      aspect,
      far,
      near,
      orthoganalEnable,
      name = "CAMERA",
      uuid = uuidv4(),
    } = this.props;
    this.initComponent(name, uuid);
    const Camera = new CameraAbstract({
      name,
      requiredPropertys,
      zoomOrhoganal,
      zoomPerspective,
      position,
      rotation,
      degreesToCameraPerspective,
      aspect,
      far,
      near,
      orthoganalEnable,
    });
    this.obj = Camera.obj;
    this.addToScene(requiredPropertys.scene);
    this.resize();
    window.addEventListener("resize", this.resize);
    this.readyComponent();
    this.setState({
      ready: Camera.obj,
    });
  }

  setZoom = (zoom) => {
    if (!zoom) return;
    this.obj.zoom = zoom;
    this.obj.updateProjectionMatrix();
  };

  resize = () => {
    const { fullscreen = true } = this.props;
    if (fullscreen) {
      this.obj.aspect = window.innerWidth / window.innerHeight;
    }
    this.obj.updateProjectionMatrix();
  };

  shouldComponentUpdate(nextProps) {
    const { zoom } = this.props;
    if (nextProps.zoom) {
      if (zoom !== nextProps.zoom) {
        this.setZoom(nextProps.zoom);
      }
    }
    this.onPropsUpdate(this.props, nextProps);
    return true;
  }

  render() {
    const { ready, uuidSelectObj } = this.state;
    if (!ready) return null;
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...this.props,
            camera: this.obj,
            uuidSelectObj: uuidSelectObj,
            setSelectedObj: this.setSelectedObj,
          });
        }
      }
    );
    return <>{childrenWithProps}</>;
  }
}

export default Camera;
