import React from "react";
import style from "./styles/index.module.scss";

const Loader = () => {
  return (
    <div className={style.wrapper}>
      <div className={style["lds-dual-ring"]}></div>
    </div>
  );
};

export default Loader;
