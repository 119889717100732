
const СontrolPanel = ({cargos, active_cargo_indx, start, end, backStep, nextStep}) => {
  const cargo = cargos[active_cargo_indx]
  return (
    <>
      <button type={'button'} onClick={start}>to start</button>
      <button type={'button'} onClick={end}>to end</button>
      <button type={'button'} onClick={backStep}>{'<<'}</button>
      <span>{active_cargo_indx+1} / {cargos.length}   </span>
      <button type={'button'} onClick={nextStep}>{'>>'}</button>
      <span> sort: {cargo ? cargo.sort : ""}</span>
      <span> id: {cargo ? cargo.id : ""}</span>
      {cargo ?
      <span> size:  
        {cargo.size[0]}; {cargo.size[1]}; {cargo.size[2]};
      </span> : ""}
    </>
  )
}

export default СontrolPanel;