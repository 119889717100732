import React, { useEffect, useState } from "react";
import SceneNew from "../GGE/SceneNew";
import { useStoreon } from "storeon/react";
import { Loader } from '../../views';

const AlgorithmComponent = () => {
  const { json, index, config } = useStoreon("json", "index", "config");
  const [success, setSuccess] = useState(true)

  useEffect(() => {
    setSuccess(false)
    const id = setTimeout(() => {
      setSuccess(true)
    }, 100)
    return () => {
      clearTimeout(id)
    }
  }, [config.opacity, json])

  return success ? <SceneNew data={json} index={index} config={config} /> : <Loader />
};

export default AlgorithmComponent;
