import React from "react";
import { allIcon, nextIcon, prevIcon, refreshIcon } from "../../images";
import style from "./styles/index.module.scss";
import classNames from "classnames";
import { closeIcon, openIcon } from "../../images";
import CargoInfo from "../CargoInfo";
import { Checkbox } from "..";

const StepsView = ({
  steps,
  onChangeSteps,
  onNext,
  onPrev,
  total,
  current,
  onRefresh,
  onAll,
  currentCargo,
  onChangeOpen,
  open,
}) => {
  return (
    <div
      className={classNames(style.steps, {
        [style["close"]]: !open,
      })}
    >
      <Checkbox
        label="Управление по шагам:"
        name="checkbox"
        checked={steps}
        onChange={onChangeSteps}
      />
      {steps ? (
        <>
          <div className={style.steps__wrapper}>
            <button
              className={classNames(
                style.steps__button,
                style.steps__button_refresh
              )}
              onClick={onRefresh}
            >
              <img
                className={style.steps__img}
                src={refreshIcon}
                alt="refresh"
              />
            </button>
            <button
              className={classNames(
                style.steps__button,
                style.steps__button_prev
              )}
              onClick={onPrev}
            >
              <img className={style.steps__img} src={prevIcon} alt="prev" />
            </button>
            <div className={style.steps__display}>{`${
              current + 1
            } / ${total}`}</div>
            <button
              className={classNames(
                style.steps__button,
                style.steps__button_next
              )}
              onClick={onNext}
            >
              <img className={style.steps__img} src={nextIcon} alt="next" />
            </button>
            <button
              className={classNames(
                style.steps__button,
                style.steps__button_all
              )}
              onClick={onAll}
            >
              <img className={style.steps__img} src={allIcon} alt="all" />
            </button>
          </div>
          {currentCargo ? <CargoInfo info={currentCargo} /> : null}
        </>
      ) : null}
      <button onClick={onChangeOpen} className={style["steps__button-open"]}>
        <img
          className={style["steps__img-open"]}
          src={!open ? closeIcon : openIcon}
          alt="button-arrow"
        />
      </button>
    </div>
  );
};

export default StepsView;
