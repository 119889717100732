import React from "react";
import style from "./styles/index.module.scss";

const LoadJsonView = ({ onChange, nameFile }) => {
  return (
    <div className={style["wrapper"]}>
      <input
        type="file"
        name="file"
        id="input__file"
        onChange={onChange}
        className={style["input"]}
      />

      <label className={style["input__file-wrapper"]} htmlFor="input__file">
        <div className={style["input__file-fake"]}>{nameFile}</div>
        <div className={style["input__file-button"]}>Выбрать</div>
      </label>
    </div>
  );
};

export default LoadJsonView;
